import dayjs from 'dayjs';
import { mapGetters } from "vuex";
import * as Helpers from "./dashboard-helpers";
import {
    userIsAtLeastDP,
    userIsAtLeastAccountant,
} from "@/app/helpers";
import * as ProblemHelpers from "./problems/problems-helpers";
import {
    ISSUE_LEAVE_CP,
    ISSUE_LEAVE_RECUP,
    ISSUE_LEAVE_RTT
} from '@/config/redmine-constants';
import { impersonate, depersonate, impersonated } from "@/utils/personate";
import Bookmarks from "@/views/common/Bookmarks.vue";
import MyLeaveModal from "./leave/MyLeaveModal.vue";
import ValidLeaveModal from "./leave/ValidLeaveModal.vue";
import AccountLeaveModal from "./leave/AccountLeaveModal.vue";
import AccountCursorsModal from "./leave/AccountCursors.vue";
import MyEntries from "@/views/resource/MyEntries.vue";
import MyNumbers from "@/views/resource/MyNumbers.vue";
import ResourceSelect from "@/views/common/ResourceSelect.vue";
import RangeSelect from "@/views/common/RangeSelect.vue";
import BigNumber from "./DashboardBigNumber.vue";
import RTTModal from "./leave/RTTModal.vue";
import IconCalendar from "@/icons/calendar-week-solid.svg";
import IconTree from "@/icons/sitemap-solid.svg";
import IconGrid from "@/icons/th-solid.svg";
import IconLogs from "@/icons/list-solid.svg";
import IconCram from "@/icons/timeline-solid.svg";
import IconGroups from "@/icons/user-group-solid.svg";
import IconRefresh from "@/icons/arrow-rotate-right-solid.svg";


export default {
    name: "Dashboard",
    components: {
        MyLeaveModal,
        ValidLeaveModal,
        AccountLeaveModal,
        AccountCursorsModal,
        MyEntries,
        MyNumbers,
        ResourceSelect,
        RangeSelect,
        IconCalendar,
        IconTree,
        IconGrid,
        IconLogs,
        IconCram,
        IconGroups,
        IconRefresh,
        Bookmarks,
        BigNumber,
        RTTModal,
    },
    data() {
        return {
            myLeaves: [],
            myLeavesRemaining: [],
            myLeaveModalOpen: false,
            myLeaveModalIssue: null,
            validLeaves: [],
            validLeaveModalOpen: false,
            accountLeaves: [],
            accountLeaveModalOpen: false,
            accountCursorsModalOpen: false,
            accountCursorsRecupModalOpen: false,
            RTTModalOpen: false,
            problems: null,
            range: [dayjs().subtract(6, 'month').toDate(), new Date()],
            ISSUE_LEAVE_CP,
            ISSUE_LEAVE_RECUP,
            ISSUE_LEAVE_RTT,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            notif: "Dashboard/list/notif",
            user: "Resource/auth/user",
        }),
        impersonated,
        myPendingHours() {
            return Helpers.showDays(this.myLeaves.reduce(Helpers.pendingReducer, 0));
        },
        mySubmittedHours() {
            return Helpers.showDays(this.myLeaves.reduce(Helpers.submittedReducer, 0));
        },
        canSubmit() {
            return userIsAtLeastDP(this.user) || userIsAtLeastAccountant(this.user);
        },
        canAccount() {
            return userIsAtLeastAccountant(this.user);
        },
        submittedHours() {
            return Helpers.showDays(this.validLeaves.reduce(Helpers.submittedReducer, 0));
        },
        validatedHours() {
            return Helpers.showDays(this.accountLeaves.reduce(Helpers.validatedReducer, 0));
        },
        problemTypes() {
            if (!this.problems) return [];
            return Object.keys(ProblemHelpers.mapping)
                .filter(key => !!this.problems[key])
                .map(key => {
                    return {
                        key,
                        label: ProblemHelpers.mapping[key].title,
                    };
                });
        },
        myRemainingCP() {
            return Math.round((this.myLeavesRemaining.cp + this.myLeavesRemaining.cpn) * 10) / 10;
        },
        myRemainingRTT() {
            return this.myLeavesRemaining.rtt;
        },
    },
    methods: {
        toggleMyLeaveModal(toggle, issueId = null) {
            this.myLeaveModalIssue = issueId;
            this.myLeaveModalOpen = toggle;
        },
        toggleValidLeaveModal(toggle) {
            this.validLeaveModalOpen = toggle;
        },
        toggleAccountLeaveModal(toggle) {
            this.accountLeaveModalOpen = toggle;
        },
        toggleAccountCursorsModal(toggle) {
            this.accountCursorsModalOpen = toggle;
        },
        toggleAccountCursorsRecupModal(toggle) {
            this.accountCursorsRecupModalOpen = toggle;
        },
        toggleRTTModal(toggle) {
            this.RTTModalOpen = toggle;
        },
        refreshLeaves() {
            this.$store.dispatch('Dashboard/list/getLeaveCounters', this.user.id).then(leavesCounter => {
                const remainings = Helpers.getRemainingLeaves(leavesCounter[0]);
                this.myLeavesRemaining = remainings;
            });
            this.$store.dispatch('Dashboard/list/getLeaves', { mode: 'my' }).then(leaves => {
                this.myLeaves = leaves;
            });
            if (this.canSubmit) {
                this.$store.dispatch('Dashboard/list/getLeaves', { mode: 'valid' }).then(leaves => {
                    this.validLeaves = leaves;
                });
            }
            if (this.canAccount) {
                this.$store.dispatch('Dashboard/list/getLeaves', { mode: 'account' }).then(leaves => {
                    this.accountLeaves = leaves;
                });
            }
        },
        refreshProblems() {
            this.$store.dispatch('Dashboard/list/countProblems').then(problems => {
                this.problems = problems;
            });
        },
        impersonateResource(resource) {
            if (!resource) return depersonate();
            impersonate(resource.login);
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('Dashboard/list/toggleNotif', true);
            vm.refreshLeaves();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('Dashboard/list/toggleNotif', false);
        next();
    },
    mounted() {
        this.$store.dispatch("Project/list/getList");
        this.refreshProblems();
    },
}
