<style lang="scss">
@import 'about';
</style>

<template>
    <div>
        <top-bar />
        <div class="m-4">
            <div class="float-lg-right">
                <h2>Documentation</h2>
                en <a target="_blank" href="https://masaogroup.sharepoint.com/:w:/s/MasaoInternalProjectsRD/EeQOsDS2NLJOmdglL6mia3oBfLczhg4E7I4BwLHVsOZRdw?e=PPnoWJ">Resource Manager guide for Consultants and Developpers</a><br>
                fr <a target="_blank" href="https://masaogroup.sharepoint.com/sites/masao/Projects/Documents%20partages/07%20-%20Formations%20et%20Proc%C3%A9dures/6%20-%20Ressources%20internes/Masao%20Guide%20Projet%20-%20Methode%20M.pdf">Guide de présentation générale sur la Méthode M</a><br>
                fr <a target="_blank" href="https://masaogroup.sharepoint.com/:p:/r/sites/masao/Projects/_layouts/15/Doc.aspx?sourcedoc=%7B0ED8757A-3BAD-4CDA-A9B5-924C5FCE6FA5%7D&file=Pr%C3%A9sentation%20g%C3%A9n%C3%A9rale%20Resource%20Manager.pptx&action=edit&mobileredirect=true&cid=3cfebc2a-f81a-439e-afa7-16716f98643f">Guide de présentation générale du Resource Manager</a><br>
                fr <a target="_blank" href="https://masaogroup.sharepoint.com/:w:/r/sites/masao/Projects/_layouts/15/Doc.aspx?sourcedoc=%7BCDDC1CEC-578D-400C-9168-357312BC4018%7D&file=Resource%20Manager-Guide%20utilisateur%20CP.docx&action=default&mobileredirect=true&cid=171140f3-18c3-49a5-8f58-36026708f106">Guide utilisateur pour les Chefs de Projets</a><br>
                fr <a target="_blank" href="https://masaogroup.sharepoint.com/:w:/r/sites/masao/Projects/_layouts/15/Doc.aspx?sourcedoc=%7BB64B171E-E604-4CB7-9A7D-D67024E94814%7D&file=Resource%20Manager-Guide%20utilisateur%20Consultant-D%C3%A9veloppeur.docx&action=default&mobileredirect=true&cid=f708afbf-05e7-4987-8cae-a234a4220951">Guide pour les Consultants/Développeurs</a><br>
                fr <a target="_blank" href="https://masaogroup.sharepoint.com/:b:/r/sites/masao/Projects/Documents%20partages/07%20-%20Pilotage/Outil%20de%20gestion%20de%20projets/Redmine-Resource%20Manager/Resource%20Manager%20-%20Activation%20du%20calendrier%20Outlook_v2.pdf?csf=1&web=1">Guide d’activation Outlook</a><br>
                fr <a target="_blank" href="https://masaogroup.sharepoint.com/:x:/r/sites/masao/Projects/_layouts/15/Doc.aspx?sourcedoc=%7BEF1D56B8-9416-4D78-9F1C-DBFD342AEC0F%7D&file=R%C3%A8gles%20Fonctionnelles%20Resource%20Manager.xlsx&action=default&mobileredirect=true">Règles Fonctionnelles</a><br>
                fr <a target="_blank" href="https://masaogroup.sharepoint.com/:x:/r/sites/masao/Projects/_layouts/15/Doc.aspx?sourcedoc=%7BEEF418CB-5E98-46E3-940E-00CD95995B90%7D&file=FAQ%20Resource%20Manager.xlsx&action=default&mobileredirect=true&cid=6d62e540-6d92-4674-b050-e8a480e06e9b">FAQ</a><br>
            </div>
            <h2>Patch notes</h2>
            <h3>v5.15.0 <small class="text-muted">30/12/2024</small></h3>
            <ul>
                <ali wi=71933 img><b>Congés</b> managment refactored & simplified</ali>
            </ul>
            <h3>v5.14.0 <small class="text-muted">20/11/2024</small></h3>
            <ul>
                <li>Planning
                    <ul>
                        <ali wi=69587 img>Select <b>Me</b> shortcut now appears when the <b>current user</b> is <b>not selected</b></ali>
                        <ali wi=68704 img><b>Saturday & Sunday</b> options added to the <b>multiple</b> planner</ali>
                    </ul>
                </li>
            </ul>
            <h3>v5.13.0 <small class="text-muted">13/08/2024</small></h3>
            <ul>
                <li>Planning
                    <ul>
                        <ali wi=66843 img>Annoying <b>tooltip staying on event</b> after move or resize <b>fixed</b> !</ali>
                        <ali img=86807>New entries created <b>after now is planned</b> (not done) & taking <b>start time</b> into account</ali>
                    </ul>
                </li>
                <ali wi=63403 img>Congés: <b>DPs</b> can now <b>manage</b> for <b>others</b></ali>
            </ul>
            <h3>v5.12.1 <small class="text-muted">15/07/2024</small></h3>
            <ul>
                <ali img="65492-steps">SPA: Days input <b>steps</b> set to <b>0.125</b> (1h) instead of <b>0.25</b></ali>
            </ul>
            <h3>v5.12.0 <small class="text-muted">09/07/2024</small></h3>
            <ul>
                <ali wi=65492 img>SPA: <b>PECC hours</b> can now be <b>mass moved</b> to sold</ali>
                <ali wi=66411>Dashboard & TeamTime: <b>Coef calculation</b> refactored</ali>
                <ali wi=65553>Congés: Cursors <b>importation</b> tool</ali>
            </ul>
            <h3>v5.11.0 <small class="text-muted">03/01/2024</small></h3>
            <ul>
                <ali wi=59060 img>Congés: It's now possible to <b>select a day</b> with already a <b>half</b> congé</ali>
                <ali wi=58949 img>CRAM + SPA: <b>Devops id</b> and link added on the <b>Excel</b> export</ali>
                <ali wi=59787>CRAM: <b>Temp j.</b> column added (Hours / 8)</ali>
            </ul>
            <h3>v5.10.0 <small class="text-muted">24/11/2023</small></h3>
            <ul>
                <ali img=74257>TimeModal: <b>Minute picker</b> now <b>loops</b> to offset hour</ali>
                <ali img=74479>Mes congés: <b>Remaining days</b> displayed with preselection on click</ali>
                <li>Planning: <b>Surprise</b> new technology used (hint: SSE)</li>
            </ul>
            <h3>v5.9.0 <small class="text-muted">22/09/2023</small></h3>
            <ul>
                <li>SPA
                    <ul>
                        <li>Project settings
                            <ul>
                                <ali wi=56398><b>Work geo restriction</b> selector</ali>
                                <ali wi=56398 img=56398a><b>Ergonomy</b> reworked for quicker config</ali>
                            </ul>
                        </li>
                    </ul>
                </li>
                <ali wi=56398 img=56398b>Global Planning: <b>Work geo restriction flags</b> added on day & project tooltips</ali>
            </ul>
            <h3>v5.8.0 <small class="text-muted">08/09/2023</small></h3>
            <ul>
                <li>Dashboard/TeamTime: Adjustments & fixes</li>
            </ul>
            <h3>v5.7.0 <small class="text-muted">30/08/2023</small></h3>
            <ul>
                <li>TeamTime: Adjustments & fixes</li>
            </ul>
            <h3>v5.6.0 <small class="text-muted">28/08/2023</small></h3>
            <ul>
                <li>Dashboard
                    <ul>
                        <li>Elements reorganized & colors simplified</li>
                        <ali wi=55078 img><b>My projects</b> numbers added</ali>
                        <ali wi=55078 img=55078b><b>My entries</b> added</ali>
                    </ul>
                </li>
                <ali wi=55078>TeamTime: <b>Entries</b> mode added (my projects/entries)</ali>
            </ul>
            <h3>v5.5.0 <small class="text-muted">24/04/2023</small></h3>
            <ul>
                <li>Congés
                    <ul>
                        <ali wi=53577 img><b>CP N-1</b> are not decreased from <b>CP N</b> anymore</ali>
                        <ali img=53577b>Date range selection now <b>shows and skips</b> public holidays and <b>days already taken</b></ali>
                    </ul>
                </li>
                <ali wi=53865>CRAM: <b>Mutiple versions</b> can be used to filter</ali>
                <li>Planning
                    <ul>
                        <ali wi=53391><b>Locked</b> entries can now be <b>copied</b> from the time modal</ali>
                        <ali><b>Copied entries</b> (from the time modal) opens the datepicker to select the <b>target day</b></ali>
                        <ali wi=53244 img>Top bar added with <b>day hours and validation</b></ali>
                    </ul>
                </li>
                <ali wi=53722 img>SPA: <b>Issue type</b> added to the <b>Excel</b> export</ali>
            </ul>
            <h3>v5.4.0 <small class="text-muted">13/03/2023</small></h3>
            <ul>
                <ali wi=49795>SPA: <b>Commerce time</b> excluded from <b>RAF</b> calculation</ali>
                <ali wi=52265>TeamTime: now opened to <b>Consultants</b> & <b>Tech Leads</b></ali>
            </ul>
            <h3>v5.3.0 <small class="text-muted">15/01/2023</small></h3>
            <ul>
                <ali img="enter_time">SPA: <b>Enter time</b> shortcut added</ali>
                <ali img=50041>Time modal
                    <ul>
                        <ali wi=50041><b>Multiple</b> mode is now usable also for <b>done</b> entries</ali>
                        <li><b>Initial date</b> now comes <b>pre-selected</b> on the <b>multiple</b> mode</li>
                    </ul>
                </ali>
                <ali wi=50335 img>TeamTime: <b>Calculated workload</b> added</ali>
            </ul>
            <h3>v5.2.0 <small class="text-muted">16/11/2022</small></h3>
            <ul>
                <ali wi=48253><b>TeamTime</b>: New DP tool</ali>
                <ali img=leave_shortcut>Dashboard: "Congé" type selection <b>shortcut</b></ali>
                <ali wi=48616>SPA: <b>Iteration Path</b> added to the <b>Excel</b> export</ali>
            </ul>
            <h3>v5.1.0 <small class="text-muted">19/10/2022</small></h3>
            <ul>
                <ali wi=11504>Resources <b>skill</b> editor</ali>
                <ali wi=47176>Time modal: Now allows entries on <b>closed tasks</b></ali>
                <ali wi=46591 img>Logs: Time entries simple <b>history</b></ali>
            </ul>
            <h3>v5.0.0 <small class="text-muted">19/09/2022</small></h3>
            <ul>
                <ali wi=45137>API <b>security improved</b>, now 100% based on Azure</ali>
                <li>Planning
                    <ul>
                        <ali img=56653><b>Short entries</b> can now be moved from the <b>left/right edges</b></ali>
                        <ali img=57119>Ctrl + click entries to <b>show the related task</b> modal</ali>
                        <ali img=57802>Task modal: Task can be <b>renamed</b> from the modal</ali>
                        <ali img=56653b><b>Resizing info</b> added on entry</ali>
                    </ul>
                </li>
            </ul>
            <h3>v4.20.0 <small class="text-muted">12/07/2022</small></h3>
            <ul>
                <li>SPA
                    <ul>
                        <ali wi=45241>Excel: <b>Days</b> showed instead of <b>hours</b></ali>
                        <ali wi=45241>Excel: Advanced fields <b>removed on simplified</b> mode</ali>
                        <ali wi=44816>Project settings: <b>Confirmation</b> added on close with <b>unsaved</b> description</ali>
                    </ul>
                </li>
                <ali wi=45202 img>CRAM: <b>Counter</b> added (+ small UI adjustments)</ali>
                <li>Dashboard
                    <ul>
                        <ali wi=44959 img><b>Validation</b> enabled for <b>accounting</b></ali>
                        <ali wi=43077 img><b>Credits</b> can now be <b>ignored</b> from the report</ali>
                    </ul>
                </li>
                <ali wi=44924 img>Planning: <b>Resizer</b> thickness <b>increased</b> to facilitate event resize</ali>
                <ali wi=45209 img>Multi Tasker: <b>Pagination</b> added</ali>
            </ul>
            <h3>v4.19.0 <small class="text-muted">20/06/2022</small></h3>
            <ul>
                <li>SPA
                    <ul>
                        <ali wi=44302 img>Project <b>notes</b></ali>
                        <ali wi=44303 img><b>Indented</b> task subject for <b>Excel</b></ali>
                    </ul>
                </li>
                <ali wi=44173 img>CRAM: <b>Only planned</b> filter</ali>
            </ul>
            <h3>v4.18.0 <small class="text-muted">02/06/2022</small></h3>
            <ul>
                <ali wi=41859>Multi Tasker: New tool allowing <b>batch</b> actions on <b>tasks</b></ali>
                <ali wi=43309>KPIs: <b>Multiple versions</b> can now be selected</ali>
            </ul>
            <h3>v4.17.0 <small class="text-muted">09/05/2022</small></h3>
            <ul>
                <ali wi=42999 img>SPA: <b>Displayed column set</b> can now be selected and saved on bookmarks</ali>
                <ali wi=43056 img>CRAM: <b>Group filter</b> added on <b>resource</b> selector</ali>
            </ul>
            <h3>v4.16.0 <small class="text-muted">19/04/2022</small></h3>
            <ul>
                <ali wi=42282 img>Dedicated <b>"Congés N-1"</b> counter</ali>
                <li>SPA
                    <ul>
                        <ali wi=41841><b>Latest used filters</b> are now persistent by project (browser storage)</ali>
                        <ali wi=41857><b>Versions</b> manager</ali>
                        <ali wi=41858 img><b>Members</b> manager</ali>
                    </ul>
                </li>
            </ul>
            <h3>v4.15.0 <small class="text-muted">11/04/2022</small></h3>
            <ul>
                <li>Planning
                    <ul>
                        <ali wi=42287><b>Spanish & Isaeli</b> public holidays added for 2022</ali>
                        <ali wi=42912><b>DST timezones</b> management improved</ali>
                    </ul>
                </li>
                <ali wi=41841 img>Global <b>bookmarks</b> can be used for SPA & Planning</ali>
            </ul>
            <h3>v4.14.0 <small class="text-muted">16/03/2022</small></h3>
            <ul>
                <ali wi=34899>Global planning: <b>"Congés" type</b> version time entries are now all <b>green</b></ali>
                <ali wi=40668>Time modal: Time entries for <b>closed tasks</b> can now be <b>updated</b></ali>
                <ali wi=38331>Profile changer: Dedicated <b>batch profile changer</b> page</ali>
            </ul>
            <h3>v4.13.0 <small class="text-muted">22/02/2022</small></h3>
            <ul>
                <ali wi=35177>KPI: SLA average <b>response delays</b></ali>
                <ali wi=28207>Dashboard: <b>New leave types</b> with dedicated counters</ali>
            </ul>
            <h3>v4.12.0 <small class="text-muted">11/02/2022</small></h3>
            <ul>
                <ali wi=40116 img>Time Modal: <b>Multiple planner</b></ali>
            </ul>
            <h3>v4.11.0 <small class="text-muted">01/02/2022</small></h3>
            <ul>
                <ali wi=39879 vid>Issue modal: Clipboard copy shareable <b>entry link</b></ali>
                <ali wi=39879>Time modal: <b>Non member</b> projects selection is now <b>allowed</b> from an entry link</ali>
            </ul>
            <h3>v4.10.0 <small class="text-muted">20/01/2022</small></h3>
            <ul>
                <ali wi=38456>Task modal: <b>Description</b> is now <b>editable</b> (basic markdown)</ali>
                <ali wi=39019>CRAM: <b>Export</b> is now <b>Excel</b></ali>
                <ali wi=39019>SPA: Can now <b>export to Excel</b></ali>
            </ul>
            <h3>v4.9.0 <small class="text-muted">12/01/2022</small></h3>
            <ul>
                <li>Individual Planning
                    <ul>
                        <ali wi=38100><b>Last task filters</b> (left) are now saved <b>by project</b> (browser storage)</ali>
                    </ul>
                </li>
                <ali wi=37541 img>Dashboard Reports: New task ano <b>En souffrance</b></ali>
                <li>CRAM
                    <ul>
                        <ali wi=36532 img><b>Planned entries</b> in red</ali>
                        <ali img=quick-tm-access>Quick <b>time modal</b> access</ali>
                    </ul>
                </li>
                <li>SPA
                    <ul>
                        <ali wi=39094 img><b>Default version</b> can now be defined on <b>project settings</b></ali>
                        <li><b>Redmine link</b> on project settings</li>
                        <ali wi=38069 img><b>CP/DP/Tech names</b> on top</ali>
                    </ul>
                </li>
                <ali wi=35177 img>C2S dashboard added</ali>
            </ul>
            <h3>v4.8.0 <small class="text-muted">10/11/2021</small></h3>
            <ul>
                <ali wi=36706 img>Time Entry: <b>Task status</b> shown</ali>
                <li>Planning
                    <ul>
                        <ali wi=35558 img=35558-count>Filtered <b>tasks count</b> shown on top of the list</ali>
                        <ali wi=37158 img>Issue modal: <b>Parent</b> can now be <b>updated</b></ali>
                        <ali wi=37726>Browser's <b>timezone</b> taken into account</ali>
                    </ul>
                </li>
                <li>Global planning
                    <ul>
                        <ali wi=37210 img><b>Group</b> shortcuts</ali>
                    </ul>
                </li>
                <ali wi=37145 img>Table <b>head sticky</b> on CRAM, Log, History, BillingMarkers, VersionsCredits</ali>
            </ul>
            <h3>v4.7.0 <small class="text-muted">01/11/2021</small></h3>
            <ul>
                <li>Planning
                    <ul>
                        <ali wi=36595><b>Today</b> now refreshes <b>tasks</b></ali>
                        <ali wi=35558 img=35558-tootip><b>Next date</b> tooltip on tasks</ali>
                    </ul>
                </li>
                <ali wi=36608>Modals: <b>External clic</b> disabled</ali>
                <ali wi=35558>Time entry: Latest profile <b>autoselected</b></ali>
                <li>SPA
                    <ul>
                        <ali wi=37093><b>Progression</b> added on time entry tooltip</ali>
                        <ali wi=37014 img=37093>Tooltip time entries <b>linked</b> to the <b>planning</b> context</ali>
                    </ul>
                </li>
                <li>
                    CRAM
                    <ul>
                        <li>Time entries <b>linked</b> to the <b>planning</b> context</li>
                        <ali wi=36265 img><b>Tooltips</b> on <b>comments</b></ali>
                    </ul>
                </li>
                <ali>Dashboard <b>reports</b>: Passed planned time entries <b>fixable directly inline</b></ali>
            </ul>
            <h3>v4.6.0 <small class="text-muted">01/09/2021</small></h3>
            <ul>
                <li>Planning
                    <ul>
                        <ali wi=35901><b>Comments are excluded</b> from the copy</ali>
                        <ali wi=35558>Improvement of <b>Shift drag</b></ali>
                        <ali wi=35558 vid=35558-copy>Shift clic to <b>initiate a copy</b></ali>
                        <ali wi=35558><b>Context maintained</b> returning to the Planning page</ali>
                        <ali wi=35558 img=35558-version>Filtre <b>version</b></ali>
                        <ali wi=35558 img=35558-month><b>Monthly View</b> added</ali>
                    </ul>
                </li>
                <li>SPA
                    <ul>
                        <ali wi=34578 img=34578><b>Moving</b> time entered: <b>Comments</b> added</ali>
                    </ul>
                </li>
                <li>Time Entry
                    <ul>
                        <ali wi=35014 img=35014>Ability to open time entries in <b>read-only</b></ali>
                        <ali wi=28372>Time entries on tasks <b>closed</b> or <b>removed</b> can no longer <b>be modified</b></ali>
                        <li><b>Delay</b> added after deletion before <b>refresh</b></li>
                    </ul>
                </li>
                <ali wi=28463>Groups Management</ali>
            </ul>
            <h3>v4.5.0 <small class="text-muted">05/07/2021</small></h3>
            <ul>
                <li><b>Titre des pages</b> mis-à-jour sur le navigateur</li>
                <ali wi=29069><b>Description</b> ajoutée sur les versions/crédits</ali>
                <li>Groups
                    <ul>
                        <ali wi=28463>Outil de création de <b>groupes d'utilisateurs</b></ali>
                        <ali wi=28463><b>Gaming</b> par groupe</ali>
                    </ul>
                </li>
            </ul>
            <h3>v4.4.0 <small class="text-muted">18/06/2021</small></h3>
            <ul>
                <ali wi=28207>Dashboard: <b>Compteur</b> de congés</ali>
                <li>Planning
                    <ul>
                        <ali wi=28638>Projets <b>triés</b> alpha</ali>
                        <li>Modal de tâche
                            <ul>
                                <li>Amélioration de l'<b>UX</b></li>
                                <ali wi=28513>Modification des <b>statut</b> & <b>priorité</b></ali>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>SPA
                    <ul>
                        <ali wi=28513>Possibilité d'ouvrir la <b>fiche</b> des tâches</ali>
                        <ali wi=28513><b>Saisie du temps</b> déplacé dans la fiche</ali>
                    </ul>
                </li>
                <ali wi=28462>CRAM: <b>Total</b> d'heures affiché</ali>
            </ul>
            <h3>v4.3.0 <small class="text-muted">08/06/2021</small></h3>
            <ul>
                <li>Planning:
                    <ul>
                        <ali wi=28035>Nouveau filtre: Tâches <b>non commencées</b></ali>
                        <ali wi=28036>Modal de tâche: Navigation sur les <b>tâches liées</b></ali>
                        <ali wi=28206><b>Thèmes de couleurs</b> pour les saisies (Avance/Retard Tâche/Saisie)</ali>
                        <li>Choix de l'<b>intervalle horaire</b> stocké sur le navigateur</li>
                        <ali wi=28208>Amélioration/<b>homogénéisation</b> de la présentation de la tâche</ali>
                    </ul>
                </li>
                <ali wi=28238>CRAM: <b>Progression</b> ajoutée avec diff</ali>
            </ul>
            <h3>v4.2.0 <small class="text-muted">31/05/2021</small></h3>
            <ul>
                <ali wi=28037>PatchNotes: Liens vers les <b>workitems</b></ali>
                <ali wi=28042>CRAM: <b>Ouvert</b> à tout le monde</ali>
                <ali wi=28076>Planning: Les congés deviennent <b>verts</b></ali>
                <ali>Planning individuel
                    <ul>
                        <ali wi=28040>Sélection de la <b>zone horaire</b> affichée</ali>
                        <ali wi=28040>Option pour cacher le <b>week-end</b></ali>
                    </ul>
                </ali>
            </ul>
            <h3>v4.0.0 <small class="text-muted">20/05/2021</small></h3>
            <ul>
                <li>Le <b>dashboard</b> devient la page d'accueil</li>
                <li>Reports: <b>Filtre</b> pour les rapports "passedIncomplete" & "profileMismatch"</li>
                <li>Planning individuel
                    <ul>
                        <li><b>Pas de 15min</b> sur le calendrier (30 précédemment)</li>
                        <li>Bouton d'affichage de la <b>journée complète</b> (Full)</li>
                        <li>Lien vers le <b>SPA</b> à la sélection d'un <b>projet</b></li>
                        <li>Shift+drag pour <b>copier</b></li>
                    </ul>
                </li>
                <li>Saisie de temps
                    <ul>
                        <li>Réalisé sélectionné <b>par défaut pour le passé</b></li>
                        <li>Bouton de <b>copie</b> pour duplication hors scope</li>
                    </ul>
                </li>
                <li>CRAM: <b>Avance/Retard</b> avant/après sur le CSV+</li>
            </ul>
            <about-old v-if="showOld" />
            <div v-else class="text-center">
                <button class="btn btn-link" @click="showOld = true">Voir le passé</button>
            </div>
        </div>
    </div>
</template>

<script>
import Ali from './AboutItem';
import AboutOld from './AboutOld';

export default {
    name: 'About',
    components: { Ali, AboutOld},
    data() {
        return {
            showOld: false,
        };
    },
};
</script>
