


















import { delegate as tippyDelegate } from "tippy.js";
import { estimateMissingCP, getRemainingLeaves } from "../dashboard-helpers";
import {
    ISSUE_LEAVE_CP,
    ISSUE_LEAVE_RECUP,
    ISSUE_LEAVE_RTT
} from '@/config/redmine-constants';

export default {
    name: "MyLeaveParts",
    props: ["value", "thatsMe", "leaveCounters"],
    data() {
        return {
            cpt: 0,
            cpn: 0,
            rtt: 0,
            recup: 0,
            hasRtts: false,
            missingCP: 0, // Since the last count
        }
    },
    computed: {
        remainingParts() {
            if (!this.leaveCounters.length) return [];
            const counters = this.leaveCounters[0];
            const remainings = getRemainingLeaves(counters);
            // this.missingCP = estimateMissingCP(counters.leaves_to_date);
            this.missingCP = 0;
            this.cpt = Math.round((remainings.cp + remainings.cpn + this.missingCP) * 10) / 10;
            this.cpn = Math.round(remainings.cpn * 10) / 10;
            this.rtt = Math.round(remainings.rtt * 10) / 10;
            this.recup = Math.round(remainings.recup * 10) / 10;
            this.hasRtts = this.leaveCounters[0].leaves_rtt !== null;
            const remainingParts = [];
            if (this.cpt) remainingParts.push({ value: this.cpt, label: 'congés', issueId: ISSUE_LEAVE_CP });
            if (this.hasRtts) remainingParts.push({ value: this.rtt, label: 'RTT', issueId: ISSUE_LEAVE_RTT });
            if (this.recup) remainingParts.push({ value: this.recup, label: 'récupération', issueId: ISSUE_LEAVE_RECUP });
            return remainingParts;
        },
        improvedParts() {
            return this.remainingParts.map(part => ({
                issueId: part.issueId,
                value: part.value,
                valueLabel: this.getValueLabel(part),
                label: this.getLabel(part),
                plus: this.getPlusText(part),
            }));
        },
    },
    methods: {
        getValueLabel(part) {
            const value = parseFloat(part.value);
            if (part.issueId === ISSUE_LEAVE_CP) {
                if (this.missingCP) return `<abbr data-tippy-content="Inclue <b>${this.missingCP}j</b> estimés depuis le dernier comptage"><b>${value}</b></abbr>`;
            }
            return `<b>${value}</b>`;
        },
        getLabel(part) {
            if (part.issueId === ISSUE_LEAVE_RTT) {
                part.label = `<abbr data-tippy-content="RTT mis à jour aux<br/><b>1er janv</b> et <b>1er juillet</b><br/>de chaque année">${part.label}</abbr>`;
            }
            return part.label;
        },
        getPlusText(part) {
            let plus = '';
            if (part.issueId === ISSUE_LEAVE_CP && this.cpn) {
                plus = (this.remainingParts.length > 1 ? '<br />' : ' ');
                plus += 'dont ' + this.cpn + ' avant juin';
            }
            if (part.issueId === ISSUE_LEAVE_RTT && this.rtt) {
                plus = '<br />avant fin déc.';
            }
            return plus;
        },
    },
    mounted() {
        tippyDelegate(this.$refs.remainingLeaves, {
            target: '[data-tippy-content]',
        });
    }
}
