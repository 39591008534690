













import Modal from "@/views/modal/Modal.vue";
import FlatPickr from 'vue-flatpickr-component';
import { French } from 'flatpickr/dist/l10n/fr.js';

export default {
    name: "RTTModal",
    components: {
        Modal,
        FlatPickr,
    },
    data() {
        return {
            date: '',
            dateConfig: {
                locale: French,
                disable: [
                    (date: Date) => date.getDay() === 0 || date.getDay() === 6
                ]
            },
            submitting: false,
        };
    },
    methods: {
        submit() {
            if (!this.date) return;
            const question = [
                'Êtes-vous sûr de vouloir imposer ce RTT ?',
                'Les activités existantes seront supprimées',
                '(excepté les congés familliaux)',
            ]
            if (!confirm(question.join('\n'))) return;
            this.submitting = true;
            this.$store.dispatch('Dashboard/list/createCorporateRTT', this.date).then(() => {
                this.$emit('submitted');
                this.close();
            }).finally(() => {
                this.submitting = false;
            });
        },
        close() {
            this.$emit('close');
        },
    },
}
