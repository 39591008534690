import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import { Draggable } from '@fullcalendar/interaction'
import * as Redmine from '@/config/redmine-constants'
import { PRIMARY_COLOR } from '@/config/constants'
import {
    loadProjectFilters,
    saveProjectFilters,
} from '@/app/calendar-helpers'
import {
    normalizedContains,
    getAssignedProjects,
} from '@/app/helpers'
import {
    addMissingParentsToList,
    toTree
} from '../tree/tree-helpers'
import ResourceSelect from "@/views/common/ResourceSelect.vue"
import GroupSelect from "@/views/common/GroupSelect.vue"
import CalendarIssueGroup from "./CalendarIssueGroup.vue"
import IssuePrio from './IssuePrio.vue'
import IconTree from "@/icons/sitemap-solid.svg"


const defaultFilters = () => ({
    assigned: 'mine',
    statuses: Redmine.DEFAULT_STATUSES_FOR_ASSIGNMENT,
    priorities: Redmine.ISSUE_PRIORITIES.map(prio => prio.id),
    search: null,
    notStarted: false,
    version: null,
})


const multipleIdsComparer = (a, b) =>
    // a.map(o => o.id).sort().join('')
    // !== b.map(o => o.id).sort().join('');
    a.sort().join('') !== b.sort().join('')
const filterComparers = {
    statuses: multipleIdsComparer,
    priorities: multipleIdsComparer,
}


export default {
    name: 'CalendarFilters',
    components: {
        ResourceSelect,
        GroupSelect,
        Multiselect,
        IconTree,
        CalendarIssueGroup,
        IssuePrio,
    },
    props: [
        'resourceI',
        'projectI',
        'smallScreen',
        'loadingTimeEntry',
    ],
    data() {
        return {
            filters: defaultFilters(),
            showFilters: false,
            versions: [],
            group: null,
            resource: this.resourceI,
            project: this.projectI,
            issues: [],
            issuesFiltered: [],
            issueCountByProject: [],
            priorities: Redmine.ISSUE_PRIORITIES,
        }
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            user: 'Resource/auth/user',
            resources: 'Resource/list/resources',
            projects: 'Project/list/projects',
            statuses: 'Enumeration/list/statuses',
            groups: 'Group/edit/groups',
        }),

        groupResources() {
            if (!this.group) return []
            const userIds = this.group.userIds.map(id => parseInt(id))
            return this.resources.filter(resource => userIds.includes(resource.id))
        },

        selectedVersionLabel() {
            if (!this.filters.version || !this.versions.length) return null
            return this.versionLabel(this.filters.version)
        },

        indexedVersions() {
            if (!this.versions.length) return {}
            const indexedVersions = this.versions.reduce((acc, version) => {
                acc[version.id] = version
                return acc
            }, {})
            return indexedVersions
        },

        projectsWithAssignedIssues() {
            if (!this.projects.length) return []
            return this.issueCountByProject.map(issCount => {
                issCount.name = this.projects.find(project => project.id === issCount.id).name
                return issCount
            }).sort((a, b) => {
                if (a.name > b.name) return 1
                if (a.name < b.name) return -1
                return 0
            })
        },

        issuesNb() {
            if (!this.issuesFiltered.length) return 0
            return this.issuesFiltered.filter((issue) => issue.matching).length
        },

        resourceProjects() {
            const resourceIds = [this.resource?.id || this.user.id]
            return (getAssignedProjects(this.projects, resourceIds))
        },

        issuesTree() {
            if (!this.issuesFiltered.length) return []
            return toTree(this.issuesFiltered)[0].children
        },

        areActiveFilters() {
            const dFilters = defaultFilters()
            return Object.keys(this.filters).some(key => {
                const a = this.filters[key]
                const b = dFilters[key]
                return filterComparers[key] ? filterComparers[key](a, b) : a !== b
            })
        },
    },

    methods: {
        normalizeResource(resource) {
            return resource.firstname + ' ' + resource.lastname
        },

        selectResource() {
            this.$emit('update:resourceI', this.resource)
            this.selectedResource()
        },
        selectedResource() {
            this.project = null
            this.selectProject()
            // this.getNotes();
            this.getCountForResourceByProject()
        },

        pickProject(projectId) {
            this.project = this.projects.find(project => project.id == projectId)
            this.selectProject()
        },

        selectProject() {
            this.$emit('update:projectI', this.project)
            this.selectedProject()
        },
        selectedProject() {
            this.refreshIssues()
            if (!this.project) return
            this.filters = this.bookmarkLoading?.filters
                || loadProjectFilters(this.project.id)
                || defaultFilters()
            this.$store.dispatch("Issue/list/getVersions", this.project.id).then(versions => {
                this.versions = [{ id: -1, name: 'Sans version', status: 'closed' }].concat(versions)
            })
        },

        searchIssues() {
            const search = this.filters.search
            if (!search) {
                this.issuesFiltered = this.issues
                return
            }
            const issuesFiltered = this.issues.filter(
                (issue) =>
                    normalizedContains(search, issue.subject) ||
                    search === issue.devops_id ||
                    search == issue.id
            )
            addMissingParentsToList(issuesFiltered, this.issues)
            this.issuesFiltered = issuesFiltered
        },

        getCountForResourceByProject() {
            if (this.loadingTimeEntry) return
            if (!this.resource) return
            const payload = {
                resourceId: this.resource.id,
            }
            this.$store.dispatch('Issue/list/getListCountForResource', payload).then((issueCountByProject) => {
                this.issueCountByProject = issueCountByProject
            })
        },

        refreshIssues() {
            if (!this.project) return this.issues = []
            saveProjectFilters(this.project.id, this.areActiveFilters ? this.filters : null)
            const params: any = {
                projectId: this.project.id,
                assigned: this.filters.assigned,
                statusIds: this.filters.statuses.join(','),
                priorityIds: this.filters.priorities.join(','),
                versionId: this.filters.version,
            }
            if (this.filters.notStarted) {
                params.notStarted = ''
            }
            const payload: any = {
                resourceId: this.resource.id,
                params,
            }
            this.$store.dispatch('Issue/list/getListForResource', payload).then((issues) => {
                this.issues = issues
                this.issuesFiltered = issues
            })
        },

        refresh() {
            this.refreshIssues()
            this.getCountForResourceByProject()
        },

        unselectProject() {
            this.project = null
            this.selectProject()
        },

        resetFilters() {
            this.filters = defaultFilters()
            this.refreshIssues()
        },

        statusLabel(id) {
            return this.statuses.find(status => status.id === id).name
        },
        priorityLabel(id) {
            return this.priorities.find(priority => priority.id === id).devops
        },
        versionLabel(id) {
            const version = this.indexedVersions[id]
            if (!version) return ''
            return version.name
        },

        selectMe() {
            this.group = null
            this.$emit('update:resourceI', this.user)
        }
    },
    watch: {
        issues() {
            this.searchIssues();
        },
        resourceI(newVal) {
            this.resource = newVal
            this.selectedResource()
        },
        projectI(newVal) {
            this.project = newVal
            this.selectedProject()
        },
    },
    mounted() {
        if (this.smallScreen) return

        const vm = this
        new Draggable(vm.$refs.issueTree, {
            itemSelector: '.issue',
            eventData: (eventEl) => {
                const issue = vm.issues.find(issue => issue.id == eventEl.dataset['id'])
                return {
                    title: issue.subject,
                    create: false,
                    color: PRIMARY_COLOR,
                }
            },
        })
    }
}
