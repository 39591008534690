import { mapGetters } from "vuex";
import dayjs from 'dayjs';
import { delegate as tippyDelegate } from "tippy.js";
import FlatPickr from 'vue-flatpickr-component';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { store } from '@/store';
import ModalArrow from "@/img/modal-arrow.svg";
import * as Helpers from "../dashboard-helpers";
import { DATE_FORMAT } from '@/config/constants';
import {
    ACTIVITY_CONSULTANT,
    ADMINISTRATIVE_PROJECT,
    ISSUE_LEAVE_ANCIEN,
    ISSUE_LEAVE_CP,
    ISSUE_LEAVE_RECUP,
    ISSUE_LEAVE_RTT
} from '@/config/redmine-constants';
import { Leave, TimeEntryInput } from "@/app/types/interfaces";
import { userIsAtLeastDP } from "@/app/helpers";
import Modal from "@/views/modal/Modal.vue";
import LeaveModalBlock from "./LeaveModalBlock.vue";
import MyLeaveParts from "./MyLeaveParts.vue";
import ResourceSelect from "@/views/common/ResourceSelect.vue";

function getDefaultNewLeave() {
    return {
        issueId: "",
        dates: [],
        comments: "",
        startHalf: false,
        endHalf: false,
    };
}

function getDefaultErrors() {
    return {
        issueId: false,
        dates: false,
    };
}

function formatLeaveTooltip(el: HTMLElement) {
    if (!el.dataset.leaves) return;
    const leaves:Leave[] = JSON.parse(el.dataset.leaves);
    const rows = leaves.map(l => {
        const status = parseInt(l.status) > 1 ? ' validé' : parseInt(l.status) < 1 ? ' refusé' : '';
        const comments = l.comments ? `<div><i>${l.comments}</i></div>` : '';
        return `<div>${l.subject}: <b>${parseFloat(l.hours) / 8}j</b>${status}${comments}</div>`;
    });
    let $tooltip = rows.join('<hr />');
    if (leaves.find(l => parseInt(l.status) <= 1)) {
        $tooltip += '<div class="tooltip_foot_text">Cliquer pour supprimer</div>';
    }
    return $tooltip;
}

function createLeaveEvent(leaves: Leave[], refresh: () => void) {
    const leaveHours = leaves.reduce((ac, l) => ac + parseFloat(l.hours), 0);
    if (!leaveHours) return;
    const eventWidth = Math.min(leaveHours, 8) / 8 * 25;
    const $event = document.createElement('span');
    $event.classList.add('flatpickr_event');
    const validated = !leaves.find(l => parseInt(l.status) <= 1);
    if (validated) $event.classList.add('validated');
    if (leaves.find(l => parseInt(l.status) < 1)) $event.classList.add('refused');
    $event.style.width = `${eventWidth}px`;
    $event.style.left = `calc(50% - ${eventWidth/2}px)`;
    $event.dataset.leaves = JSON.stringify(leaves);
    if (!validated) {
        $event.addEventListener('click', (e) => {
            const leaves = (e.target as HTMLElement).dataset.leaves;
            if (!leaves) return;
            e.stopPropagation();
            removeLeaves(JSON.parse(leaves)).then(refresh);
        });
    }
    return $event;
}

function removeLeaves(leaves: Leave[]) {
    if (!leaves.length) return;
    const timeEntries = leaves.filter(l => parseInt(l.status) <= 1).map(l => ({ id: l.id }));
    return store.dispatch('Crud/removeTimeEntry', { timeEntries });
}

export default {
    name: "MyLeaveModal",
    components: {
        Modal,
        LeaveModalBlock,
        FlatPickr,
        ModalArrow,
        ResourceSelect,
        MyLeaveParts,
    },
    props: ["selectIssueId"],
    data() {
        return {
            leaveIssues: [],
            dateConfig: {
                locale: French,
                mode: 'range',
                onDayCreate: this.onDayCreate,
                inline: true,
            },
            newLeave: getDefaultNewLeave(),
            errors: getDefaultErrors(),
            recup: null,
            leavesAll: [],
            publicHolidays: [],
            selectedResource: null,
            leaveCounters: [],
            selectedLeaves: [],
            rangeIsSelected: false,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            user: "Resource/auth/user",
        }),
        selectedDaysNb() {
            let daysNb = this.newLeave.dates.length;
            if (this.newLeave.startHalf) daysNb -= .5;
            if (this.newLeave.endHalf) daysNb -= .5;
            if (!daysNb) return 1;
            return daysNb;
        },
        selectedLeaveDaysNb() {
            return this.selectedLeaves.reduce((ag, l: Leave) => {
                return ag += parseFloat(l.hours)
            }, 0) / 8
        },
        overrun() {
            if (!this.newLeave.issueId) return false;
            if ((this.newLeave.issueId === ISSUE_LEAVE_CP || this.newLeave.issueId === ISSUE_LEAVE_ANCIEN) && this.selectedDaysNb > this.$refs.leaveParts.cp) return true;
            if (this.newLeave.issueId === ISSUE_LEAVE_RTT && this.selectedDaysNb > this.$refs.leaveParts.rtt) return true;
            if (this.newLeave.issueId === ISSUE_LEAVE_RECUP && this.selectedDaysNb > this.$refs.leaveParts.recup) return true;
            return false;
        },
        canAddForOthers() {
            return userIsAtLeastDP(this.user);
        },
        thatsMe() {
            return this.selectedResource === this.user;
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        showDays(hours) {
            return Helpers.showDays(hours);
        },
        showDate(date) {
            return dayjs(date).format('ddd D MMM');
        },
        leavesForStatus(status) {
            return this.leavesAll.filter((leave: Leave) => leave.status === status);
        },
        changedIssue() {
            this.errors.issueId = false;
        },
        updateRange(selectedRange) {
            this.newLeave.dates = [];
            this.newLeave.startHalf = false;
            this.newLeave.endHalf = false;
            this.errors.dates = false;
            this.selectedLeaves = [];
            this.rangeIsSelected = true;
            if (selectedRange.length !== 2) return;
            const fullLeaveDays = Helpers.groupHoursByDay(this.leavesAll).filter(l => l.hours > 4).map(l => l.spent_on);
            const skipDates = this.publicHolidays.concat(fullLeaveDays);
            const startDate:Date = selectedRange[0];
            const endDate:Date = selectedRange[1];
            this.newLeave.dates = Helpers.getBusinessDates(startDate, endDate, skipDates);
            this.selectedLeaves = Helpers.getDeletableLeavesBetween(startDate, endDate, this.leavesAll);
        },
        clearRange() {
            this.newLeave.dates = [];
            this.$refs.newLeaveDatePicker.fp.clear();
            this.rangeIsSelected = false;
        },
        validateNewLeaves() {
            if (this.overrun) return false;
            this.errors.issueId = !this.newLeave.issueId;
            this.errors.dates = !this.newLeave.dates.length;
            return !Object.values(this.errors).find(error => error);
        },
        createLeaves() {
            this.errors = getDefaultErrors();
            if (!this.validateNewLeaves()) return;
            const leave = this.newLeave;
            const input: Partial<TimeEntryInput> = {
                issue: { id: leave.issueId },
                comment: leave.comments,
                user: { id: this.selectedResource.id },
                project: { id: ADMINISTRATIVE_PROJECT },
                activityId: ACTIVITY_CONSULTANT,
                multiple: leave.dates.map((spent_on, i) => ({
                    spent_on,
                    hours: Helpers.leaveHoursForIndex(leave, i),
                })),
            };
            this.$store.dispatch('Crud/upsertTimeEntry', input).then(() => {
                this.$gtag.event('Create Leaves', {
                    event_category: 'Leave Modal',
                    value: this.selectedDaysNb,
                });
                this.newLeave = getDefaultNewLeave();
                this.submitted();
            });
        },
        deleteLeaves() {
            // if (!window.confirm('Êtes-vous sûr de vouloir supprimer les congés sélectionnés ?')) return;
            removeLeaves(this.selectedLeaves).then(this.submitted);
        },
        submitted() {
            this.clearRange();
            this.refresh();
            if (!this.thatsMe) return;
            this.$store.dispatch('Dashboard/list/heartbeat');
            this.$emit('submitted');
        },
        refreshLeaveCounters() {
            this.$store.dispatch('Dashboard/list/getLeaveCounters', this.selectedResource.id).then((leaveCounters) => {
                this.leaveCounters = leaveCounters;
            });
        },
        refreshLeaves() {
            const payload: Record<string, string> = { mode: 'all' };
            if (!this.thatsMe) payload.userId = this.selectedResource.id
            this.$store.dispatch('Dashboard/list/getLeaves', payload).then((data: Leave[]) => {
                this.leavesAll = data;
                this.$refs.newLeaveDatePicker.fp.redraw();
            });
        },
        refresh() {
            if (!this.selectedResource) this.selectedResource = this.user;
            this.refreshLeaveCounters();
            this.refreshLeaves();
        },
        onDayCreate(dObj, dStr, fp, dayElem: HTMLElement) {
            //@ts-ignore
            const date = dayElem.dateObj;
            const dateFormatted = dayjs(date).format(DATE_FORMAT);
            const dayOff = date.getDay() === 0 || date.getDay() === 6
                || this.publicHolidays.indexOf(dateFormatted) !== -1;
            if (dayOff) dayElem.classList.add('flatpickr_dayoff');
            const leaves = this.leavesAll.filter(l => l.spent_on === dateFormatted);
            const $event = createLeaveEvent(leaves, this.refresh);
            if ($event) dayElem.appendChild($event);
        },
    },
    mounted() {
        this.refresh();
        const from = dayjs().subtract(200, 'day').format(DATE_FORMAT);
        const to = dayjs().add(200, 'day').format(DATE_FORMAT);
        this.$store.dispatch('TimeEntry/list/getPublicHolidays', { from, to }).then(data => {
            this.publicHolidays = data.public_holidays.filter(entry => entry.country === this.selectedResource.country).map(entry => entry.date);
        });
        this.$store.dispatch('Issue/list/getList', { projectId: ADMINISTRATIVE_PROJECT }).then(data => {
            this.leaveIssues = data;
        });
        if (this.selectIssueId) this.newLeave.issueId = this.selectIssueId;
        tippyDelegate('.flatp', {
            target: '[data-leaves]',
            content: formatLeaveTooltip,
        });
    },
};
