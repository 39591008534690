import dayjs from 'dayjs';
import FlatPickr from 'vue-flatpickr-component';
import { French } from 'flatpickr/dist/l10n/fr.js';


const startOfPenultimateYear = dayjs().set('month', -1).subtract(1, 'year').startOf('year');
const startOfPreviousYear = dayjs().set('month', -1).startOf('year');
const twelveMonths = dayjs().subtract(12, 'month');
const sixMonths = dayjs().subtract(6, 'month');
const startOfYear = dayjs().set('month', 0).startOf('year');
const now = new Date();
const entriesRanges = [
    { label: startOfPenultimateYear.format('YYYY'), range: [startOfPenultimateYear.toDate(), dayjs(startOfPenultimateYear).endOf('year').toDate()] },
    { label: startOfPreviousYear.format('YYYY'), range: [startOfPreviousYear.toDate(), dayjs(startOfPreviousYear).endOf('year').toDate()] },
    { label: startOfYear.format('YYYY'), range: [startOfYear.toDate(), now] },
    { label: '12 mois', range: [twelveMonths.toDate(), now] },
    { label: '6 mois', range: [sixMonths.toDate(), now] },
];


export default {
    props: {
      value: {},
    },
    components: {
        FlatPickr,
    },
    data() {
        return {
            entriesRanges,
            entriesRange: this.value,
            dateConfig: {
                locale: French,
                mode: 'range',
            },
        };
    },
    computed: {
        selectedRange() {
            return this.entriesRanges.find(er => 
                dayjs(er.range[0]).isSame(this.entriesRange[0], 'day')
             && dayjs(er.range[1]).isSame(this.entriesRange[1], 'day')
            );
        },
    },
    methods: {
        selectRange(range) {
            if (range.length != 2) return;
            this.$emit('input', range);
            this.entriesRange = range;
        },
    },
};
